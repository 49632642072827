import "./App.css";
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PublicRoute from "./Utils/PublicRoute";
import PrivateRoute from "./Utils/PrivateRoute";
import DirectPage from "./DirectPage";
import LoginPage from "./LoginPage";
import LoginSuccessPage from "./LoginSuccessPage";
import DnaPage from "./DnaPage";
import { getToken, removeToken, authUser } from "./Utils/Common";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { authLoading: true };
    this.UID = new URLSearchParams(this.props.location.search).get("uid");
  }

  componentDidMount() {
    const token = getToken();
    if (!token) {
      return;
    }

    if (!this.UID) {
      removeToken();
    } else {
      authUser(token).then((data) => {
        if (data.success === 1) {
          if (data.user.uid === this.UID) {
            this.setState({ authLoading: false });
            return;
          }
        }

        removeToken();
        this.setState({ authLoading: false });
      });
    }
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/direct" component={DirectPage} />
          <Route path="/dna" component={DnaPage} />
          <PublicRoute path="/login" component={LoginPage} />
          <PrivateRoute path="/page" component={LoginSuccessPage} />
        </Switch>
      </div>
    );
  }
}
