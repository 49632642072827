import React, { Component } from "react";
import {
  AiOutlineSafetyCertificate,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { dnaAuth } from "./Utils/Common";

export default class LoginSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: false, finish: false, uid: 0 };
    this.picc_enc = new URLSearchParams(this.props.location.search).get("e");
    this.cmac = new URLSearchParams(this.props.location.search).get("c");
  }

  componentDidMount() {
    if (!this.picc_enc || !this.cmac) {
      this.setState({ auth: false, finish: true });
    } else {
      dnaAuth(this.picc_enc, this.cmac).then((data) => {
        if (data.success === 1) {
          this.setState({ auth: true, finish: true, uid: data.uid });
          return;
        }
        this.setState({ auth: false, finish: true });
      });
    }
  }

  render() {
    if (!this.state.finish)
      return (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#c7ecee",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h2>Authentication...</h2>
        </div>
      );

    return (
      <div
        style={{
          position: "absolute",
          backgroundColor: "#c7ecee",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h1>ABA ROBOTICS</h1>
          <h2>NFC Authentication System</h2>
          <div className="auth-success">
            <div>
              {this.state.auth ? (
                <div>
                  <AiOutlineSafetyCertificate
                    style={{ fontSize: "10rem", color: "green", marginTop: 50 }}
                  />
                  <h2>Authenticated</h2>
                </div>
              ) : (
                <div>
                  <AiOutlineCloseCircle
                    style={{ fontSize: "10rem", color: "red", marginTop: 50 }}
                  />
                  <h2>Authentication Failed</h2>
                </div>
              )}
            </div>
            <h5>{this.state.uid ? "UID: " + this.state.uid : ""}</h5>
          </div>
        </div>
      </div>
    );
  }
}
