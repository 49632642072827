export const dnaAuth = async (PICC_ENC, CMAC) => {
  const credentials = {
    picc_enc: PICC_ENC,
    cmac: CMAC,
  };

  return fetch("https://abarobotics.com/api_demo/dna_auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
};

export const loginUser = async (password, UID) => {
  const credentials = {
    uid: UID,
    password: password,
  };

  return fetch("https://abarobotics.com/api_demo/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
};

export const authUser = async (token) => {
  return fetch("https://abarobotics.com/api_demo/user_info", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  }).then((data) => data.json());
};

export const setToken = (userInfo) => {
  sessionStorage.setItem("token", userInfo.token);
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const removeToken = () => {
  sessionStorage.removeItem("token");
};
