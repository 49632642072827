import React, { Component } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { loginUser, setToken } from "./Utils/Common";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      password: "",
      info: "",
    };
    this.UID = new URLSearchParams(this.props.location.search).get("uid");
    console.log(this.UID);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const res = await loginUser(this.state.password, this.UID);
    if (!this.UID) this.setState({ info: "UID is empty" });
    else if (res.success === 1) {
      setToken(res);
      window.location.href = "/page?uid=" + this.UID;
    } else this.setState({ info: res.message });
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          backgroundColor: "#c7ecee",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <form
          onSubmit={(event) => this.handleSubmit(event)}
          style={{
            padding: 40,
            width: "100%",
            textAlign: "center",
          }}
        >
          <h1>Login</h1>
          <TextField
            name="password"
            fullWidth
            required
            InputLabelProps={{ required: false }}
            size="small"
            margin="normal"
            type="password"
            label="Password"
            variant="outlined"
            onChange={(event) =>
              this.setState({
                password: event.target.value,
              })
            }
          />
          <Typography style={{ color: "red" }} variant="subtitle2">
            {this.state.info}
          </Typography>
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              type="submit"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
