import React, { Component } from "react";

export default class DirectPage extends Component {
  constructor(props) {
    super(props);
    this.UID = new URLSearchParams(this.props.location.search).get("uid");
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          backgroundColor: "#c7ecee",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h1>ABA ROBOTICS</h1>
          <h2>NFC Authentication System</h2>
          <h5 style={{ marginTop: 50 }}>
            {this.UID ? "UID: " + this.UID : "UID: empty"}
          </h5>
        </div>
      </div>
    );
  }
}
