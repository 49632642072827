import React, { Component } from "react";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { removeToken } from "./Utils/Common";

export default class LoginSuccessPage extends Component {
  constructor(props) {
    super(props);
    this.UID = new URLSearchParams(this.props.location.search).get("uid");
  }

  componentDidMount() {
    removeToken();
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          backgroundColor: "#c7ecee",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <h1>ABA ROBOTICS</h1>
          <h2>NFC Authentication System</h2>
          <div className="auth-success">
            <div>
              <AiOutlineSafetyCertificate
                style={{ fontSize: "10rem", color: "green", marginTop: 50 }}
              />
              <h2>Authenticated</h2>
            </div>
            <h5>{this.UID ? "UID: " + this.UID : "UID: empty"}</h5>
          </div>
        </div>
      </div>
    );
  }
}
